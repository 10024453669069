* {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Open Sans, Helvetica Neue, sans-serif;
  //font-family: "Segoe UI", "Chinese Quote", Tahoma, SansSerif, sans-serif;
}

html, #root, .scroll-fix, .content {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: rgb(240, 242, 245) !important;
  font-size: 13px;
}

a {
  text-decoration: none;
}

.scroll-fix {
  width: calc(100vw - 20px);
}

@media only screen and (max-width: 500px) {
  .scroll-fix {
    width: 100vw;
  }
}

/* Notification */
.notification-container {
  width: 350px !important;
  color: #36a2eb !important;
}
.notification {
  opacity: 1 !important;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
  border-radius: 4px !important;
  margin-bottom: 13px !important;
  margin-top: 13px !important;
  padding: 15px 15px 15px 68px !important;
}

.notification-error {
  background-color: #d32f2f !important;
}

.notification:before {
  left: 25px !important;
  font-size: 19px !important;
}

/* Scroll */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
::-webkit-scrollbar-thumb:hover {
  background: #a7a7a7;
}
